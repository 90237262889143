import {getValidationSchema} from "./validation.js";

export const getOrderSchema = (order) => {
    const schema = {
        client: ['object'],
        total: ['float'],
        orderLines: ['array'],
        orderStatus: ['object'],
        partner: ['object'],
        startDate: ['date'],
        endDate: ['date'],
        invoiceDate: ['date'],
        isPrivate: ['required']
    }

    if (order.orderStatus?.settings?.needsCar) {
        schema.car = ['object'];
    }

    if (order.orderStatus?.settings?.needsEndDate) {
        schema.endDate.push('required');
    }

    if (order.orderStatus?.settings?.needsStartDate) {
        schema.startDate.push('required');
    }

    if (order.orderStatus?.settings?.needsInvoiceData) {
        schema.invoiceDate.push('required');
        if (!order.noInvoiceNumber) {
            schema.invoiceNumber = ['required']
        }
    }

    if (order.orderStatus?.settings?.needsPaymentType) {
        schema.paymentType = ['object']
    }

    if (order.orderStatus?.settings?.needsMileage) {
        schema.mileage = ['required'];
    }

    if (order.mileage) {
        schema.mileage = ['integer'];
    }

    if (order.isPrivate === false) {
        schema.insurerClaimId = ['required'];
        schema.insurer = ['object'];
    }

    if (order.insurerClaimId) {
        schema.insurer = ['object'];
    }

    return getValidationSchema(schema)
}
export const getOrderLineSchema = (orderLine) => {
    const schema = {
        article: ['object'],
        quantity: ['required', 'float'],
        price: ['required', 'float'],
        discount: ['integer'],
        totalPrice: ['required', 'float']
    }
    if (orderLine.article.settings?.mandatoryLineNote === true) {
        schema.additionalInformation = ['required']
    }

    if (orderLine.article.articleType.hasStock) {
        schema.useStock = ['required']
    }

    if (orderLine.useStock) {
        schema.supplier = ['object']
    }
    return getValidationSchema(schema)
}

export const getPaintOrderLineSchema = () => getValidationSchema({
    article: ['object'],
    damageLevel: ['object'],
    childLines: ['childLines']
})

export const getClientSchema = () => getValidationSchema({
    name: ['required'],
    email: ['email'],
    phoneNumber: ['required'],
    identificationId: ['required'],
    clientType: ['required', 'object'],
    address: ['required'],
    city: ['required'],
    country: ['required'],
    discount: ['integer']
})

export const getCarSchema = () => getValidationSchema({
    carModel: ['required', 'object'],
    registrationNumber: ['required', ['regex', '^[ABCEHKMOPTXY0-9]+$', 'Грешен рег. номер. Провери латиница или премахни ненужни знаци']],
    client: ['required', 'object']
})

export const getArticleSchema = (article) => {
    const schema = {
        name: ['required', ['min', 2], ['max', 100]],
        sellingPrice: ['required', 'float'],
        quantityType: ['object'],
        articleType: ['object']
    };

    if (article.articleType?.isProduct) {
        schema.skuNumber = ['required'];
        schema.manufacturer = ['required'];
        schema.oemNumber = ['required'];
    }
    return getValidationSchema(schema);
};

export const getUserSchema = (user) => {
    const schema = {
        name: ['required'],
        email: ['required', 'email']
    }
    if (user.password || !user.id) {
        schema.password = ['password', ['min', 6]]
    }

    return getValidationSchema(schema);
}

export const getArticleStockChangeSchema = () => {
    const schema = {
        quantity: ['float', 'not-zero'],
        note: ['required']
    }

    return getValidationSchema(schema);
}

export const getArticleStockSchema = () => {
    const schema = {
        article: ['object'],
        supplier: ['object'],
        costPrice: ['float'],
        stockQuantity: ['float']
    };
    return getValidationSchema(schema);
}