import {Button, Card, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";

const SuppliersTable = ({
    suppliers,
    showTable
                               }) => {
    return (
        <Card>
            {showTable() ? (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th className={'col-1'}>№</th>
                        <th className={'col-2'}>Име</th>
                        <th className={'col-2 d-none d-md-table-cell'}></th>
                    </tr>
                    </thead>

                    <tbody>
                    {suppliers.map((supplier) => (
                        <tr key={supplier.id}>
                            <td className={'col-1'}>{supplier.id}</td>
                            <td className={'col-2'}>
                                <Link to={`/settings/suppliers/${supplier.id}`}>{supplier.name}</Link>
                            </td>
                            <td className={'col-2 d-none d-md-table-cell'}>
                                <Button variant="link">
                                    <Link to={`/settings/suppliers/${supplier.id}/edit`}>Редактирай</Link>
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default SuppliersTable;