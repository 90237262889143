import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchArticleTypes} from "../../../slices/articleTypeListSlice.js";
import SelectInput from "../../../components/Elements/SelectInput.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const ArticleTableFilters = (
    {
        onFilterChange,
        showContent
    }) => {
    const dispatch = useDispatch();
    const {data: articleTypes} = useSelector((state) => state.articleTypeList);
    const [filterArticleType, setFilterArticleType] = useState();

    useEffect(() => {
        dispatch(fetchArticleTypes());
    }, []);

    const handleFilterChange = (name, value) => {
        setFilterArticleType(value);
        onFilterChange('articleTypeId', value);
    }

    return (
        <div className={'mb-3'}>
            <Row>
                <Col md={3} style={{visibility: showContent() ? 'visible' : 'hidden'}}>
                    <SelectInput
                        hasAllOption={true}
                        label="Артикул тип"
                        name="articleType"
                        selectOptions={articleTypes.filter(type => !type.isSettingValue)}
                        value={filterArticleType?.id || ''}
                        onChange={handleFilterChange}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default ArticleTableFilters;