import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams} from "react-router-dom";
import {
    fetchArticleOrders,
    resetStateData as resetOrderListStateData,
    setCurrentPage
} from "../../../slices/orderListSlice.js";
import OrdersTable from "../../Orders/List/components/OrdersTable.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import ArticleNav from "./components/ArticleNav.js";

const ArticleOrdersPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { data: orders, loading, pagination } = useSelector((state) => state.orderList);
    const { data: article } = useSelector((state) => state.article);

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Артикул № ${id} - ${article.name}` );
    }, [article.id]);


    useEffect(() => {
        return () => {
            dispatch(resetOrderListStateData());
        }
    }, []);

    const handlePageChange = (page) => {
        dispatch(setCurrentPage(page));
    };

    useEffect(() => {
        dispatch(fetchArticleOrders({page: pagination.currentPage, id: id}));
    }, [dispatch, pagination.currentPage, id]);

    const showTable = () => {
        return !loading && article.id;
    }

    return (
        <SingleViewLayout
            title={title}
            navComponent={ArticleNav}
            navProps={{id: id, activeKey: 'orders'}}
            showPage={showTable()}
        >
            <OrdersTable orders={orders} showTable={() => !loading}/>
            <PaginationComponent onPageChange={handlePageChange} pagination={pagination}/>

        </SingleViewLayout>
    );
};

export default ArticleOrdersPage;
