import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";import React from "react";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import PhoneNumberInput from "../../../../components/Elements/PhoneNumberInput.js";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import {useSelector} from "react-redux";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";
import {hasPermission} from "../../../../utils/permissions.js";

const ClientForm = ({
    client,
    onAttributeChange,
    errors,
    clientTypes,
                    }) => {
    const { user } = useSelector((state) => state.auth);


    return (
        <Form>

            <Form.Group>
                <Row>
                    <Col md={2}>
                        <SelectInput
                            label={'Тип клиент'}
                            value={client.clientType ? client.clientType.id : ''}
                            name={'clientType'}
                            errors={errors}
                            onChange={onAttributeChange}
                            selectOptions={clientTypes}
                        />
                    </Col>
                    <Col md={3}>
                        <FormInput
                            label={client.clientType?.label === 'person' ? "ЕГН" : client.clientType?.label === 'entity' ? "ЕИК" : 'ЕГН/ЕИК'}
                            name="identificationId" value={client.identificationId}
                            onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Col md={3} style={{visibility: client.clientType?.id === 2 ? 'visible' : 'hidden'}}>
                        <FormInput label="ДДС номер" name="vatNumber" value={client.vatNumber}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={3} style={{visibility: client.clientType?.id === 2 ? 'visible' : 'hidden'}}>
                        <FormInput label="МОЛ" name="molField" value={client.molField}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormInput label="Име" name="name" value={client.name}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={4}>
                        <FormInput label="Email" name="email" value={client.email}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Col md={4}>
                        <PhoneNumberInput value={client.phoneNumber} name={'phoneNumber'} onChange={onAttributeChange}
                                          errors={errors}/>
                    </Col>


                    <Col md={4}>
                        <FormInput label="Адрес" name="address" value={client.address}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Col md={2}>
                        <FormInput label="Град" name="city" value={client.city}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Col md={2}>
                        <FormInput label="Пощенски код" name="zip" value={client.zip}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Col md={2}>
                        <FormInput label="Държава" name="country" value={client.country}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Row>
                        <Col md={2}>
                            <FormNumericInput label={'Отстъпка'} name={'discount'} value={client.discount} onChange={onAttributeChange} errors={errors} />
                        </Col>
                    </Row>

                    {hasPermission(user, 'manage-insurers') && (
                    <Row className={'mt-3'}>
                        <Col md={2}>
                            <FormCheckbox
                                name={'isInsurer'}
                                label={'Застраховател'}
                                onCheckboxChange={onAttributeChange}
                                isChecked={client.isInsurer}
                            />
                        </Col>
                    </Row>
                    )}
                    <Col md={12} className={'mt-3'}>
                        <FormInput as={'textarea'} label="Допълнителна информация" name="additionalInformation"
                                   value={client.additionalInformation}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    )
}

export default ClientForm;