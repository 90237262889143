import {Card} from "react-bootstrap";
import OrdersTable from "./Orders/List/components/OrdersTable.js";
import React from "react";

const HomeOrdersCard = (
    {
        title,
        orders,
    }
) => {

    return (
        <Card className={'mt-3'}>
            <Card.Header>
                <h5>{title}</h5>
            </Card.Header>
            <Card.Body>
                <OrdersTable orders={orders} includeLoadingComponent={false}/>
            </Card.Body>
        </Card>

    )
};

export default HomeOrdersCard;