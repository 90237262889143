import { Card, Table} from "react-bootstrap";
import React from "react";
import {toFloatBgn} from "../../../../utils/functions.js";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import {formatDateToBeautifulString} from "../../../../utils/dateFunctions.js";
import {CardImage, EyeFill, PencilSquare} from "react-bootstrap-icons";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {Link} from "react-router-dom";

const OrdersTable = (
    {
        orders,
        showTable = () => true,
        includeLoadingComponent = true
    }) => {

    return (
        <Card>
            {showTable ? (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>№</th>
                        <th>Клиент</th>
                        <th>Кола</th>
                        <th>Статус</th>
                        <th>Дата приемане</th>
                        <th>Дата издаване</th>
                        <th>Фактура</th>
                        <th>Изпълнител</th>
                        <th>Застраховател</th>
                        <th>Номер щета</th>
                        <th>Стойност</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map((order) => (
                        <tr key={order.id}>
                            <td>{order.id}</td>
                            <td><Link to={`/clients/${order.client.id}`} target={'_blank'}>{order.client.name}</Link><br/>{order.client.phoneNumber}</td>
                            <td>
                                {order.car &&
                                    <Link to={`/cars/${order.car?.id}`} target={'_blank'}>{order.car?.carModel.carManufacturer.name} {order.car?.carModel.name} - {order.car?.registrationNumber}</Link>
                                }
                            </td>
                            <td>{order.orderStatus.name}</td>
                            <td>{order.startDate ? formatDateToBeautifulString(order.startDate) : ''}</td>
                            <td>{order.endDate ? formatDateToBeautifulString(order.endDate) : ''}</td>
                            <td>
                                {order.invoiceDate ? formatDateToBeautifulString(order.invoiceDate) : ''}
                                {order.invoiceDate && <br />}
                                {order.invoiceNumber ? `№ ${order.invoiceNumber}` : ''}
                            </td>
                            <td>{order.partner.name}</td>
                            <td>{order.insurer?.name}</td>
                            <td>{order.insurerClaimId ? order.insurerClaimId : 'частен ремонт'}</td>
                            <td>{toFloatBgn(order.total)}</td>
                            <td>
                                    <TableLinkIconButton to={`/orders/${order.id}`}><EyeFill /></TableLinkIconButton>
                                    <TableLinkIconButton to={`/orders/${order.id}/edit`}><PencilSquare /></TableLinkIconButton>
                                    <TableLinkIconButton to={`/orders/${order.id}/files`}><CardImage /></TableLinkIconButton>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <>
                {includeLoadingComponent && (<LoadingComponent/>)}
                </>
            )}
        </Card>
    )
}

export default OrdersTable;