import React, {useState, useEffect} from 'react';
import DatePicker from "../components/Elements/DatePicker.js";
import {Button} from "react-bootstrap";
import {dateToYmdFormat, formatDateToBeautifulString} from "../utils/dateFunctions.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {fetchOrders, resetStateData} from "../slices/homeListSlice.js";
import HomeOrdersCard from "./HomeOrdersCard.js";
import LoadingComponent from "../components/LoadingComponent.js";
import {setBrowserTabTitle} from "../slices/informationMessagesSlice.js";

const Home = (
) => {
    const dispatch = useDispatch();

    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [dateRange, setDateRange] = useState(() => {
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 7);

        return {
            from: today,
            to: nextWeek
        };
    });

    const [ordersInRange, setOrdersInRange] = useState({
        ordersStartDate: [],
        ordersEndDate: []
    });
    const [statusOrders, setStatusOrders] = useState({
        releasedOrders: [],
        invoicedOrders: []
    });

    const {loading: ordersLoading} = useSelector((state) => state.homeList);
    const handleDatePickerSelect = (selected) => {
        setDateRange({
            from: selected?.from ? selected.from : null,
            to: selected?.to ? selected.to : null
        });
    }

    useEffect(() => {
        loadStatusOrders();
        dispatch(setBrowserTabTitle('Начало'));
        setIsInitialLoad(false);
        return () => {
            dispatch(resetStateData());
        };
    }, []);


    useEffect(() => {
        if (!showDatePicker && dateRange.from && dateRange.to) {
            loadDateRangeOrders();
        }
    }, [showDatePicker]);

    const loadStatusOrders = async () => {
        const releasedOrders = await dispatch(fetchOrders({
            filters: {
                orderStatusLabel: 'released'
            },
            perPage: 1000
        })).unwrap();

        const invoicedOrders = await dispatch(fetchOrders({
            filters: {
                orderStatusLabel: 'invoiced'
            },
            perPage: 1000
        })).unwrap();
        setStatusOrders({
            releasedOrders: releasedOrders.data,
            invoicedOrders: invoicedOrders.data
        });
    }

    const loadDateRangeOrders = async () => {
        const fromFilter = dateToYmdFormat(new Date(dateRange.from));
        const toFilter = dateToYmdFormat(new Date(dateRange.to));

        const ordersStartDate = await dispatch(
            fetchOrders({
                filters: {
                    dateField: "start_date",
                    from: fromFilter,
                    to: toFilter
                },
                perPage: 1000
            })
        ).unwrap();

        const ordersEndDate = await dispatch(
            fetchOrders({
                filters: {
                    dateField: "end_date",
                    from: fromFilter,
                    to: toFilter
                },
                perPage: 1000
            })
        ).unwrap();

        setOrdersInRange({
            ordersStartDate: ordersStartDate.data,
            ordersEndDate: ordersEndDate.data
        })
    };

    const showTable = () => {
        return !ordersLoading || isInitialLoad;
    }

    return (
        <div>
            <Row>
                <Col md={6}>
                    <h4 className={'mb-3'}>
                        {(dateRange.from && dateRange.to) ? (
                            <div>
                                {formatDateToBeautifulString(dateRange.from)} до {formatDateToBeautifulString(dateRange.to)}
                            </div>
                        ) : <>Избери дати</>}
                    </h4>
                </Col>
                <Col md={6} className="d-flex flex-column align-items-end">
                    <Button onClick={() => setShowDatePicker(!showDatePicker)}>
                        {showDatePicker ? 'Скрий' : 'Покажи'} календар
                    </Button>
                </Col>
                {showDatePicker && (
                    <DatePicker onSelect={handleDatePickerSelect} preSelected={dateRange}/>
                )}
                {!showDatePicker && (
                <div className={'position-relative'}>
                    {showTable() ? (
                        <Col md={12}>
                            <HomeOrdersCard title={'За приемане'} orders={ordersInRange.ordersStartDate}/>
                            <HomeOrdersCard title={'За издаване'} orders={ordersInRange.ordersEndDate}/>
                            <HomeOrdersCard title={'Издадени'} orders={statusOrders.releasedOrders}/>
                            <HomeOrdersCard title={'Фактурирани'} orders={statusOrders.invoicedOrders}/>
                        </Col>
                    ) : (
                        <LoadingComponent/>
                    )}
                </div>
                )}
            </Row>
        </div>
    );
};

export default Home;