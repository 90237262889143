import React from 'react';
import { Form } from 'react-bootstrap';

function SelectInput(
    {
        label,
        name,
        value,
        onChange,
        selectOptions,
        disabled = false,
        errors = [],
        hasAllOption = false
    }) {
    const selectedValue = value ? value : '';

    const handleChange = (e) => {
        const selectedItem = selectOptions.find(option => option.id === parseInt(e.target.value));
        onChange(name, selectedItem);
    }

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Select value={selectedValue}
                         name={name}
                         onChange={handleChange}
                         isInvalid={!!errors[name]}
                         disabled={disabled}
            >
                {hasAllOption ? (
                    <option value={''}>Всички</option>
                ) : (
                    <option disabled={true} value={''}>Избери</option>
                )}

                {selectOptions.map((selectOption) => (
                    <option key={selectOption.id} value={selectOption.id}>
                        {selectOption.searchLabel ? selectOption.searchLabel : selectOption.name}
                    </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default SelectInput;
