import React from 'react';

function ViewField(
    {
        label,
        value,
    }) {

    return (
        <div className={'view-field'}>
            <span>{label && (<b>{label}:</b>)} {value}</span>
        </div>
        );
}

export default ViewField;
