import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchOrders, resetStateData} from "../../../slices/orderListSlice.js";
import OrdersTable from "./components/OrdersTable.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";

const OrderListPage = () => {
    const dispatch = useDispatch();
    const {data: orders, loading, pagination} = useSelector((state) => state.orderList);
    const [searchParams, setSearchParams] = useState({page: 1, search: '', filters: {}, sort: ['id', 'desc']})

    useEffect(() => {
        dispatch(resetStateData());
    }, []);

    useEffect(() => {
        dispatch(fetchOrders(searchParams));
    }, [searchParams]);

    const handlePageChange = (page) => {
        setSearchParams({
            page: page,
            filters: searchParams.filters,
            search: searchParams.search,
            sort: searchParams.sort
        })
    };

    const handleQueryChange = (query) => {
        setSearchParams({
            page: 1,
            filters: searchParams.filters,
            search: query,
            sort: searchParams.sort
        })
    }

    const showTable = () => {
        return !loading;
    }

    return (
        <div>
            <ListHeaderLayout
                title={'Поръчки'}
                createLink={'/orders/create/edit'}
                onQueryChange={handleQueryChange}
            />
            <OrdersTable orders={orders} showTable={showTable()}/>
            <PaginationComponent
                onPageChange={handlePageChange}
                pagination={pagination}
            />

        </div>
    );
};

export default OrderListPage;
