import Form from "react-bootstrap/Form";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import { toFloatBgn} from "../../../../utils/functions.js";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import React, {useEffect, useState} from "react";
import {fetchArticles, resetStateData as articleListResetStateData} from "../../../../slices/articleListSlice.js";
import {useDispatch} from "react-redux";
import FormInput from "../../../../components/Elements/FormInput.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card, Table} from "react-bootstrap";
import FormRadio from "../../../../components/Elements/FormRadio.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";

const EditOrderLineComponent = (
    {
        onAttributeChange,
        editOrderLine,
        onArticleChange,
        errors,
        onPriceRelatedChange,
        articleTypeLabel
    }) => {
    const dispatch = useDispatch();
    const [articleStockAvailable, setArticleStockAvailable] = useState();

    useEffect(() => {
        dispatch(articleListResetStateData());
        return () => {
            dispatch(articleListResetStateData());
        };
    }, [editOrderLine.orderLineType]);

    const getSupplierOptions = () => {
        return editOrderLine.article.articleStocks.map(articleStock => articleStock.supplier);
    };

    const handleSupplierChange = (name, value) => {
        const articleStock = editOrderLine.article.articleStocks.find(
            articleStock => articleStock.supplier?.id === value.id
        );
        setArticleStockAvailable(articleStock.stockQuantity);
        onAttributeChange('costPrice', articleStock.costPrice);
        onAttributeChange('supplier', value);
        onAttributeChange('articleStock', articleStock);
        if (editOrderLine.orderLineType.isCost) {
            onPriceRelatedChange('price', articleStock.costPrice);
        }
    };

    const handleUseStockChange = (name, value) => {
        if (value && getSupplierOptions().length === 1) {
            handleSupplierChange('supplier', getSupplierOptions()[0]);
        }
        if (!value) {
            onAttributeChange('supplier', null);
            onAttributeChange('costPrice', 0)
        }
        onAttributeChange('useStock', value)
    }

    return (
        <Form.Group className="mb-3" controlId="formHorizontalEmail">
            <SearchAutoCompleteComponent
                onItemChange={onArticleChange}
                item={editOrderLine.article ? editOrderLine.article : null}
                name={'article'}
                label={'Артикул'}
                fetchItems={fetchArticles}
                reducer={'articleList'}
                errors={errors}
                searchFilters={{'article_type_label': articleTypeLabel, 'article_type_label_not': 'car_detail'}}
                disabled={!isNaN(editOrderLine.id)}
                resetStateData={articleListResetStateData}
            />

            {editOrderLine.article && (
                <>
                    <Row className={'mt-2'}>
                        <Col md={12}>
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th className={'col-5'}>Количество</th>
                                    <th className={'col-1'}>Мярка</th>
                                    <th className={'col-2'}>Цена</th>
                                    <th className={'col-2'}>Отстъпка</th>
                                    <th className={'col-2'}>Цена общо</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'col-5'}>
                                        <Row>
                                            <Col sm={3}>
                                                <FormNumericInput
                                                    name={'quantity'}
                                                    value={editOrderLine.quantity}
                                                    onChange={onPriceRelatedChange}
                                                    errors={errors}
                                                    disabled={!isNaN(editOrderLine.id)}
                                                    notNull={true}
                                                />
                                            </Col>
                                        </Row>
                                    </td>
                                    <td className={'col-1'}>{editOrderLine.article.quantityType.name}</td>

                                    <td className={'col-2'}>
                                        <FormNumericInput
                                            name={'price'}
                                            value={editOrderLine.price}
                                            onChange={onPriceRelatedChange}
                                            errors={errors}
                                            disabled={!isNaN(editOrderLine.id)}
                                            notNull={true}
                                        />
                                    </td>
                                    <td className={'col-2'}>
                                        <FormNumericInput
                                            name={'discount'}
                                            value={editOrderLine.discount}
                                            onChange={onPriceRelatedChange}
                                            errors={errors}
                                            disabled={!isNaN(editOrderLine.id)}
                                            notNull={true}
                                        />
                                    </td>
                                    <td className={'col-2'}>{toFloatBgn(editOrderLine.totalPrice)}</td>

                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {editOrderLine.article?.articleType.hasStock && (

                        <Card>
                            <Card.Body>
                                <Row>
                                    {getSupplierOptions().length > 0 ? (
                                        <Col md={12}>

                                            <FormRadio
                                                label='Използвай складова наличност?'
                                                options={[{value: true, label: 'Да'}, {value: false, label: 'Не'}]}
                                                selectedValue={editOrderLine.useStock !== null ? editOrderLine.useStock : ''}
                                                onRadioChange={handleUseStockChange}
                                                name="useStock"
                                                inline={true}
                                                errors={errors}
                                                disabled={!isNaN(editOrderLine.id)}
                                            />

                                            {editOrderLine.useStock ? (
                                                <Row>
                                                    <Col md={4}>
                                                        <SelectInput
                                                            label={'Избери доставчик'}
                                                            errors={errors}
                                                            onChange={handleSupplierChange}
                                                            selectOptions={getSupplierOptions()}
                                                            value={editOrderLine.supplier?.id ? editOrderLine.supplier.id : ''}
                                                            name={'supplier'}
                                                            disabled={!isNaN(editOrderLine.id)}
                                                        />
                                                        {(editOrderLine.supplier && editOrderLine.useStock) && (
                                                            <>
                                                                <span>Наличност: {articleStockAvailable} {editOrderLine.article?.quantityType.abbrevation}</span>
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                            ) : ''}

                                        </Col>) : (
                                        <Col md={12}>
                                            <p className={'text-muted'}>Артикулът няма добавена складова наличност</p>
                                        </Col>
                                    )}
                                    <Col md={3}>
                                        <FormNumericInput
                                            label={'Покупна цена с ДДС'}
                                            name={'costPrice'}
                                            value={editOrderLine.costPrice}
                                            onChange={onAttributeChange}
                                            errors={errors}
                                            disabled={!isNaN(editOrderLine.id)}
                                        />
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    )}

                    <FormInput
                        as={'textarea'}
                        name={'additionalInformation'}
                        value={editOrderLine.additionalInformation}
                        label={'Допълнителна информация'}
                        errors={errors}
                        onChange={onAttributeChange}
                    />
                </>
            )}
        </Form.Group>
    );
};

export default EditOrderLineComponent;