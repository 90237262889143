import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {hasPermission} from "../utils/permissions.js";
import {Accordion, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Bag, CarFrontFill, CartCheckFill, FilePerson, GearFill, HouseFill} from "react-bootstrap-icons";

const MenuComponent = ({ user}) => {
    const [activeKey, setActiveKey] = useState(null);
    const location = useLocation();
    const naviate = useNavigate();

    const handleToggleActive = (active) => {
        toggleActive(active)
    }

    const handleLogout = () => {
        naviate('/logout');
    };

    useEffect(() => {
        setActiveKey(null);
        if (location.pathname.startsWith('/settings')) {
            setActiveKey('settings');
        }
    }, [location.pathname]);

    const toggleActive = (key) => setActiveKey(activeKey === key ? null : key);

    return (
        <>
            <div className={'menu-items'}>
                <div className={'menu-top-item'}>
                    <NavLink to="/home"><HouseFill/> Начало</NavLink>
                </div>
                {hasPermission(user, 'get-orders') &&
                    <>
                    <div className={'menu-top-item'}>
                        <NavLink to="/orders"><CartCheckFill /> Поръчки</NavLink>
                    </div>
                        <div className={'menu-top-item ps-4'}>
                            <NavLink to="/orders/create/edit">Добави поръчка</NavLink>
                        </div>
                    </>
                }
                {hasPermission(user, 'get-clients') &&
                    <div className={'menu-top-item'}>
                        <NavLink to="/clients"><FilePerson/> Клиенти</NavLink>
                        <div className={'menu-top-item ps-4'}>
                            <NavLink to="/clients/create/edit">Добави клиент</NavLink>
                        </div>
                    </div>}
                {hasPermission(user, 'get-articles') &&
                    <div className={'menu-top-item'}>
                        <NavLink to="/articles"><Bag/> Артикули</NavLink>
                        <div className={'menu-top-item ps-4'}>
                            <NavLink to="/articles/create/edit">Добави артикул</NavLink>
                        </div>
                    </div>}
                {hasPermission(user, 'get-cars') &&
                    <div className={'menu-top-item'}>
                        <NavLink to="/cars"><CarFrontFill/> Коли</NavLink>
                        <div className={'menu-top-item ps-4'}>
                            <NavLink to="/cars/create/edit">Добави кола</NavLink>
                        </div>
                    </div>
                }
                {hasPermission(user, 'manage-settings') &&
                    <Accordion activeKey={activeKey} className={'menu-top-item'}>
                        <div
                            className={activeKey === 'settings' ? 'active' : ''}
                            onClick={() => handleToggleActive('settings')}
                            style={{cursor: 'pointer'}}
                        >
                            <GearFill /> Настройки
                        </div>
                        <Accordion.Collapse eventKey="settings">
                            <>
                            {hasPermission(user, 'get-car-models') &&
                                <>
                                <div>
                                    <NavLink to="/settings/car-models">Модели</NavLink>
                                </div>
                                <div>
                                    <NavLink to="/settings/car-manufacturers">Производители</NavLink>
                                </div>
                                </>
                            }
                            {hasPermission(user, 'get-users') &&
                                <>
                                <div>
                                    <NavLink to="/settings/users">Потребители</NavLink>
                                </div>
                                <div>
                                    <NavLink to="/settings/roles">Роли</NavLink>
                                </div>
                                </>
                            }
                                {hasPermission(user, 'manage-suppliers') &&
                                    <>
                                        <div>
                                            <NavLink to="/settings/suppliers">Доставчици</NavLink>
                                        </div>
                                    </>
                                }
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                }
            </div>
            <div className="position-absolute bottom-0 left-0 pb-2">
                <Button variant="danger"
                        onClick={handleLogout}>Logout</Button>
            </div>
        </>
    )
}

export default MenuComponent;