import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";
import React, {useEffect, useState} from "react";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import {fetchClients, resetStateData as clientListResetStateData} from "../../../../slices/clientListSlice.js";
import {fetchCarModels, resetStateData as carModelListResetStateData} from "../../../../slices/carModelListSlice.js";
import DateInput from "../../../../components/Elements/DateInput.js";
import {Link} from "react-router-dom";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import {getHpFromKw} from "../../../../utils/functions.js";

const CarForm = (
    {
        car,
        onAttributeChange,
        errors,
        forceClient
    }) => {
    const [hp, setHp] = useState(null);

    useEffect(() => {
        if (car.kw) {
            setHp(getHpFromKw(car.kw));
        }
    }, []);

    const handleHpChange = (name, value) => {
        setHp(value);
        onAttributeChange('kw', Math.round(value * 0.7354));
    }

    const handleKwChange = (name, value) => {
        const kw = Math.round(value);
        onAttributeChange(name, kw);
        setHp(getHpFromKw(kw));
    }

    return (
        <Form>

            <Form.Group>
                <Row>
                    <Col md={4}>
                        <SearchAutoCompleteComponent
                            onItemChange={onAttributeChange}
                            item={forceClient ? forceClient : car.client}
                            name={'client'}
                            label={'Клиент'}
                            fetchItems={fetchClients}
                            reducer={'clientList'}
                            errors={errors}
                            disabled={!!forceClient}
                            resetStateData={clientListResetStateData}
                        />
                    </Col>
                    <Col md={4}>
                        <FormInput label="Рег. номер"
                                   name="registrationNumber"
                                   value={car.registrationNumber}
                                   onChange={onAttributeChange}
                                   errors={errors}
                                   helpText={'Въведи латиница и числа (напр. СС1010АА)'}
                        />
                    </Col>
                </Row>
                <Row>
                    <Row>
                        <Col md={3}>
                            <SearchAutoCompleteComponent
                                onItemChange={onAttributeChange}
                                item={car.carModel}
                                name={'carModel'}
                                label={'Марка/Модел'}
                                fetchItems={fetchCarModels}
                                reducer={'carModelList'}
                                errors={errors}
                                itemLabel={'modelManufacturerName'}
                                resetStateData={carModelListResetStateData}
                            />
                        </Col>
                        <Col md={2} className="d-flex flex-column align-items-start">
                            <div className={'mt-auto'}>
                                <Link className={'small'} to={'/settings/car-models/create/edit'} target={'_blank'}>нов
                                    модел</Link>
                                <br/>
                                <Link className={'small'} to={'/settings/car-manufacturers/create/edit'}
                                      target={'_blank'}>нов производител</Link>
                            </div>

                        </Col>
                        <Row>
                            <Col md={2}>
                                <FormInput label={'Модификация'} errors={errors} name={'modification'}
                                           onChange={onAttributeChange} value={car.modification}/>
                            </Col>
                            <Col md={2}>
                                <FormNumericInput label={'Кубатура'} errors={errors} name={'cubature'}
                                           onChange={onAttributeChange} value={car.cubature}/>
                            </Col>
                            <Col md={2}>
                                <FormNumericInput label={'Киловати (kW)'} errors={errors} name={'kw'}
                                           onChange={handleKwChange} value={car.kw}/>
                            </Col>
                            <Col md={2}>
                                <FormNumericInput label={'Конски сили (HP)'} errors={errors} name={'hp'}
                                           onChange={handleHpChange} value={hp}/>
                            </Col>
                        </Row>
                    </Row>
                    <Col md={4}>
                        <FormInput label="ВИН"
                                   name="vinNumber"
                                   value={car.vinNumber}
                                   onChange={onAttributeChange}
                                   errors={errors}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <DateInput label={'Първа регистрация'} dateValue={car.firstRegisteredAt}
                                   name={'firstRegisteredAt'}
                                   onDateValueChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormInput
                            name={'paintCode'}
                            value={car.paintCode ? car.paintCode : ''}
                            onChange={onAttributeChange}
                            label={'Код боя'}
                            errors={errors}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormInput as={'textarea'} label="Допълнителна информация" name="additionalInformation"
                                   value={car.additionalInformation}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    )
};

export default CarForm;