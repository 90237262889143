import {Button, Card, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";

const UsersTable = ({
                       users,
                       showTable
                   }) => {

    return (
        <Card>
            {showTable() ? (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>№</th>
                        <th>Име</th>
                        <th>E-Mail</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>
                                <Button variant="link">
                                    <Link to={`/settings/users/${user.id}/edit`}>Редактирай</Link>
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </Table>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default UsersTable;