import { Card, Table} from "react-bootstrap";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {CarFrontFill, CartCheckFill, EyeFill, PencilSquare} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

const ClientsTable = ({
    showTable,
    clients
                      }) => {
    return (
        <Card>
            {showTable() ? (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>№</th>
                        <th>Име</th>
                        <th>Email</th>
                        <th>Телефон</th>
                        <th>ЕГН/ЕИК</th>
                        <th>Тип</th>
                    </tr>
                    </thead>
                    <tbody>
                    {clients.map((client) => (
                        <tr key={client.id}>
                            <td>{client.id}</td>
                            <td><Link to={`/clients/${client.id}`}>{client.name}</Link></td>
                            <td>{client.email}</td>
                            <td>{client.phoneNumber}</td>
                            <td>{client.identificationId}</td>
                            <td>{client.clientType.name}</td>
                            <td>
                                <TableLinkIconButton to={`/clients/${client.id}`}><EyeFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/clients/${client.id}/edit`}><PencilSquare /></TableLinkIconButton>
                                <TableLinkIconButton to={`/clients/${client.id}/cars`}><CarFrontFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/clients/${client.id}/orders`}><CartCheckFill /></TableLinkIconButton>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </Table>
            ) : (
                <LoadingComponent/>

            )}
        </Card>
    )
}

export default ClientsTable;