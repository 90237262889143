import Form from "react-bootstrap/Form";
import { toFloatBgn} from "../../../../utils/functions.js";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import React, {useEffect} from "react";
import {
    fetchCarDetailArticles,
    fetchPaintArticles,
    resetStateData
} from "../../../../slices/articleListSlice.js";
import {useDispatch, useSelector} from "react-redux";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import FormInput from "../../../../components/Elements/FormInput.js";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import {fetchDamageLevels} from "../../../../slices/damageLevelListSlice.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Button, Table} from "react-bootstrap";
import {v4 as uuidv4} from "uuid";
import SearchAutoCompleteStaticComponent from "../../../../components/SearchAutoCompleteStaticComponent.js";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";
import {
    setCarDetailArticles,
    setDamageLevels,
    setPaintArticles
} from "../../../../slices/paintPdrOrderLineDataSlice.js";

const EditOrderLinePaintComponent = (
    {
        editOrderLine,
        onArticleChange,
        errors,
        onAttributeChange
    }) => {
    const dispatch = useDispatch();
    const {data: appSettings} = useSelector((state) => state.appSettings);
    const {damageLevels, carDetailArticles, paintArticles} = useSelector((state) => state.paintPdrOrderLine);

    useEffect(() => {
        return () => {
            dispatch(resetStateData());
        };
    }, [])

    useEffect(() => {
        if (!damageLevels.length) {
            dispatch(fetchDamageLevels()).then((result) => {
                dispatch(setDamageLevels(result.payload.data));
            });
        }
        if (!carDetailArticles.length) {
            dispatch(fetchCarDetailArticles()).then((result) => {
                dispatch(setCarDetailArticles(result.payload.data));
            });
        }
        if (!paintArticles.length) {
            dispatch(fetchPaintArticles()).then((result) => {
                const data = result.payload.data;
                const filteredSettings = appSettings.filter(setting =>
                    /^paint_\d+_\d+_article_id$/.test(setting.key)
                );
                filteredSettings.sort((a, b) => {
                    const aMatch = a.key.match(/paint_(\d+)_/);
                    const bMatch = b.key.match(/paint_(\d+)_/);
                    const aIndex = parseInt(aMatch ? aMatch[1] : 0);
                    const bIndex = parseInt(bMatch ? bMatch[1] : 0);
                    return aIndex - bIndex;
                });
                const paintArticles = filteredSettings.map(setting => {
                    const matchingArticle = data.find(article => article.id === parseInt(setting.value));
                    return matchingArticle ? matchingArticle : null;
                });
                dispatch(setPaintArticles(paintArticles));
            });
        }
        dispatch(resetStateData());
    }, [editOrderLine.id]);

    const handleChildLineChange = (line, name, value) => {
        const childLinesCopy = editOrderLine.childLines.map(childLine => ({
            ...childLine
        }));
        const updatedChildLines = childLinesCopy.map(childLine => {
            if (childLine.id === line.id) {
                const updatedLine = {...childLine, [name]: value};
                updatedLine.totalPrice = calculateChildLineLineTotal(updatedLine);
                return updatedLine;
            }
            return childLine;
        });

        onAttributeChange('childLines', updatedChildLines);
        updateParentLineValues(updatedChildLines);
    };

    const updateParentLineValues = (childLines) => {
        const overallTotal = childLines.length ? childLines.reduce((sum, line) => sum + line.totalPrice, 0) : 0;
        const overallTotalWithoutDiscount = childLines.length ? childLines.reduce((sum, line) => sum + line.price * line.quantity, 0) : 0;
        const overallDiscount = childLines.length ? Math.round((1 - (overallTotal / overallTotalWithoutDiscount)) * 100) : 0;

        onAttributeChange('discount', overallDiscount);
        onAttributeChange('totalPrice', parseFloat(overallTotal.toFixed(2)));
        onAttributeChange('price',  parseFloat(overallTotalWithoutDiscount.toFixed(2)));
    }


    const calculateChildLineLineTotal = (line) => {
        return (line.quantity * line.price) * ((100 - line.discount) / 100);
    }

    const paintArticleHasLine = (article) => {
        return editOrderLine.childLines.some(
            (childLine) =>
                childLine.article.id === article.id && childLine.isActive === true
        );
    };

    const addChildLine = (article) => {
        const childLine = {
            id: uuidv4(),
            article: article,
            price: 0,
            costPrice: null,
            discount: editOrderLine.discount,
            quantity: 0,
            orderLineType: editOrderLine.orderLineType,
            order: editOrderLine.order,
            useStock: null,
            articleStock: null,
            supplier: null,
            childLines: [],
            additionalInformation: null,
            isActive: true,
            totalPrice: 0
        };
        const updatedChildLines = [...editOrderLine.childLines];
        updatedChildLines.push(childLine);
        onAttributeChange('childLines', updatedChildLines);
        onAttributeChange('quantity', 1)
    }

    const deleteChildLine = (childLineToDelete) => {
        const inactiveChildLines = [...editOrderLine.inactiveChildLines];

        const updatedChildLines = editOrderLine.childLines.map((childLine) => {
            if (childLine.id === childLineToDelete.id) {
                if (typeof childLine.id === 'number' && !isNaN(childLine.id)) {
                    const updatedChildLine = { ...childLine, isActive: false };
                    inactiveChildLines.push(updatedChildLine);
                    return null;
                }
                return null;
            }
            return childLine;
        }).filter(Boolean);
        onAttributeChange('childLines', updatedChildLines);
        onAttributeChange('inactiveChildLines', inactiveChildLines);
        updateParentLineValues(updatedChildLines);
    };

    return (
        <>
            {(damageLevels.length && carDetailArticles.length && paintArticles.length) ?
                <Form.Group className="mb-3" controlId="formHorizontalEmail">
                    <Row>
                        <Col md={5}>
                            <SearchAutoCompleteStaticComponent
                                errors={errors}
                                name={'article'}
                                onItemChange={onArticleChange}
                                items={carDetailArticles}
                                label={'Детайл'}
                                item={editOrderLine.article ? editOrderLine.article : null}
                                />
                        </Col>
                        <Col md={5}>
                            <SelectInput
                                label={'Степен на увреждане'}
                                value={editOrderLine.damageLevel ? editOrderLine.damageLevel.id : ''}
                                name={'damageLevel'}
                                errors={errors}
                                onChange={onAttributeChange}
                                selectOptions={damageLevels}
                            />
                        </Col>
                        <Col md={2}>
                            <FormInput
                                name={'paintCode'}
                                value={editOrderLine.paintCode ? editOrderLine.paintCode : ''}
                                onChange={onAttributeChange}
                                label={'Код боя'}
                                errors={errors}
                            />
                        </Col>
                    </Row>
                    <Row className={'mt-2'}>
                        <Col md={12}>
                            <Row>
                            {paintArticles.map((article) => (
                                <Col md={2} className={'small'} key={`checkbox-paint-${article.id}`}>
                                    <FormCheckbox
                                        name={`paint-article-${article.id}`}
                                        label={article.name}
                                        isChecked={paintArticleHasLine(article)}
                                        onCheckboxChange={() => addChildLine(article)}
                                        disabled={paintArticleHasLine(article)}
                                    />
                                </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                    <Row className={'mt-2'}>
                        <Col md={12}>
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th className={'col-3'}></th>
                                    <th className={'col-2'}>Количество</th>
                                    <th className={'col-1'}>Мярка</th>
                                    <th className={'col-2'}>Цена</th>
                                    <th className={'col-1'}>Отстъпка</th>
                                    <th className={'col-2'}>Цена общо</th>
                                    <th className={'col-1'}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {editOrderLine.childLines.map((childLine, index) =>
                                    (
                                        <tr key={childLine.id}
                                            style={childLine.article.articleType.label === 'chamber' ? {
                                                border: '3px solid black',
                                                'padding': '10px'
                                            } : {}}>
                                            <td className={'col-3'}>{childLine.article.name}</td>
                                            <td className={'col-2'}>
                                                <FormNumericInput
                                                    name={'quantity'}
                                                    value={childLine.quantity}
                                                    onChange={(name, value) => {

                                                        handleChildLineChange(childLine, name, value)
                                                    }}
                                                    notNull={true}
                                                    errors={errors[`childLines[${index}].quantity`] !== '' ? {quantity: errors[`childLines[${index}].quantity`]} : {}}
                                                />
                                            </td>
                                            <td className={'col-1'}>{childLine.article.quantityType.name}</td>

                                            <td className={'col-2'}>
                                                <FormNumericInput
                                                    name={'price'}
                                                    value={childLine.price}
                                                    onChange={(name, value) => {
                                                        handleChildLineChange(childLine, name, value)
                                                    }}
                                                    notNull={true}
                                                    errors={errors[`childLines[${index}].price`] !== '' ? {price: errors[`childLines[${index}].price`]} : {}}
                                                />
                                            </td>
                                            <td className={'col-1'}>
                                                <FormNumericInput
                                                    name={'discount'}
                                                    value={childLine.discount}
                                                    onChange={(name, value) => {
                                                        handleChildLineChange(childLine, name, value)
                                                    }}
                                                    notNull={true}
                                                />
                                            </td>
                                            <td className={'col-2'}>{toFloatBgn(childLine.totalPrice)}</td>
                                            <td><Button className={'small-button'} variant={'danger'} onClick={() => deleteChildLine(childLine)}>Изтрий</Button></td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h5>Цена общо (с ДДС): {toFloatBgn(editOrderLine.totalPrice)}</h5>
                        </Col>
                    </Row>
                </Form.Group> :
                <LoadingComponent/>
            }
        </>
    )
};

export default EditOrderLinePaintComponent;