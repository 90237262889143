import React from 'react';
import apiClient from "../../../axios.js";
import {Button} from "react-bootstrap";

const DocumentPdf = (
    {
        id,
        type,
        details
    }) => {

    const downloadPdf = async () => {
        try {
            const response =  await apiClient.get(`/documents/generate/${id}/${type}?details=${details}`, {
                headers: {
                    'Accept': 'application/pdf'
                },
                responseType: 'blob'
            });
            const status = await response.status;
            if (status !== 200) {
                throw new Error('Network response was not ok');
            }

            const url = window.URL.createObjectURL(response.data);

            const newWindow = window.open(url);
            if (newWindow) newWindow.focus();

        } catch (error) {
            console.error('There was an error downloading the PDF:', error);
        }
    };

    const getButtonText = () => {
        let text = '';
        if (type === 'proforma') {
            text = 'Проформа';
            if (details) {
                text += ' (детайлна)';
            }
        } else if (type === 'invoice') {
            text = 'Фактура';
        }
        return text;
    }

    return (
        <Button onClick={downloadPdf} className={'me-2'} variant={'success'}>
            {getButtonText()}
        </Button>
    );
};

export default DocumentPdf;
