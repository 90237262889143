import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {toFloatBgn} from "../../../utils/functions.js";
import OrderNav from "./components/OrderNav.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatDateToBeautifulString} from "../../../utils/dateFunctions.js";
import OrderLinesTableComponent from "./components/OrderLinesTableComponent.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import ViewField from "../../../components/Elements/ViewField.js";
import {Table} from "react-bootstrap";
import DocumentPdf from "../../Clients/Invoice/DocumentPdf.js";

const OrderViewPage = () => {
    const {id} = useParams();
    const {data: order, loading} = useSelector((state) => state.order);
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Поръчка № ${id}`);
    }, [order.id]);

    const showPage = () => {
        return !loading;
    }

    return (
        <SingleViewLayout
            title={title}
            showPage={showPage()}
            navComponent={OrderNav}
            navProps={{id: id, activeKey: 'view'}}
        >
            <Row>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Създадена'} value={formatDateToBeautifulString(order.createdAt)}/>
                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Дата фактура'}
                                   value={order.invoiceDate ? formatDateToBeautifulString(order.invoiceDate) : ''}/>
                        <ViewField label={'Номер фактура'} value={order.invoiceNumber}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Дата приемане'}
                                   value={order.startDate ? formatDateToBeautifulString(order.startDate) : ''}/>
                        <ViewField label={'Дата издаване'}
                                   value={order.endDate ? formatDateToBeautifulString(order.endDate) : ''}/>

                        <ViewField label={'Клиент'} value={order.client?.name}/>
                        <ViewField label={'Кола'} value={order.car?.registrationNumber}/>
                        <ViewField label={'Пробег'} value={order.mileage}/>
                        <ViewField label={'ЕИК/ЕГН'} value={order.client?.identificationId}/>
                        <ViewField label={'ДДС номер'} value={order.client?.vatNumber}/>
                        <ViewField label={'МОЛ'} value={order.client?.molField}/>
                        <ViewField label={'Телефон'} value={order.client?.phoneNumber}/>
                        <ViewField label={'Email'} value={order.client?.email}/>
                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Клиент фактура'} value={order.invoiceClient?.name}/>
                        <ViewField label={'Застраховател'} value={order.insurer?.name}/>
                        <ViewField label={'Номер щета'} value={order.insurerClaimId}/>

                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Изпълнител'} value={order.partner?.name}/>
                        <ViewField label={'Статус'} value={order.orderStatus?.name}/>
                        <ViewField label={'Начин плащане'} value={order.paymentType?.name}/>

                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Бележка'} value={order.additionalInformation}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className={'ms-auto'}>
                    <DocumentPdf type={'proforma'} id={id} details={true}/>

                    <DocumentPdf type={'proforma'} id={id} details={false}/>
                </div>
            </Row>
            <Row className={'mt-3'}>
                <h5>Позиции</h5>
                <OrderLinesTableComponent lines={order.orderLines} showingActiveLines={true} viewOnly={true}/>

                <div>
                    <div><b>Данъчна основа:</b> {toFloatBgn(order.totalWithoutVat)}</div>
                    <div><b>ДДС:</b> {toFloatBgn(order.vatOfTotal)}</div>
                    <div><b>Сума за плащане:</b> {toFloatBgn(order.total)}</div>
                </div>
            </Row>

            <Row className={'mt-3'}>
                <h5>Коментари</h5>
                <div>
                    <Table bordered hover striped>
                        <thead>
                        <tr>
                            <th>Действие</th>
                            <th>Пояснение</th>
                            <th>Коментар</th>
                            <th>Потребител</th>
                            <th>Дата</th>
                        </tr>
                        </thead>
                        <tbody>
                        {order.orderComments.map((comment) => (
                            <tr>
                                <td>{comment.action}</td>
                                <td>{comment.actionDetail}</td>
                                <td>{comment.comment}</td>
                                <td>{comment.user.id}</td>
                                <td>{formatDateToBeautifulString(comment.createdAt)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </Row>
        </SingleViewLayout>
    );
};

export default OrderViewPage;
