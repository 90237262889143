import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchArticles, resetStateData} from "../../../slices/articleListSlice.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import ArticlesTable from "./components/ArticlesTable.js";
import ArticleTableFilters from "./ArticleTableFilters.js";

const ArticleListPage = () => {
    const dispatch = useDispatch();
    const {data: articles, loading, pagination} = useSelector((state) => state.articleList);
    const [searchParams, setSearchParams] = useState({page: 1, search: '', filters: {articleTypeLabel: ''}})

    useEffect(() => {
        dispatch(resetStateData());
    }, []);

    useEffect(() => {
        dispatch(fetchArticles(searchParams));
    }, [searchParams]);

    const handlePageChange = (page) => {
        setSearchParams({
            page: page,
            filters: searchParams.filters,
            search: searchParams.search
        })
    };

    const handleQueryChange = (query) => {
        setSearchParams({
            page: 1,
            filters: searchParams.filters,
            search: query
        })
    }

    const handleFilterChange = (name, value) => {
        const newFilters = {
            ...searchParams.filters,
            [name]: value?.id ? value.id : '',
            articleTypeLabel: ''
        };
        setSearchParams({
            ...searchParams,
            page: 1, // Reset page to 1 if needed
            filters: newFilters
        });
    }

    const showTable = () => {
        return !loading;
    }

    return (
        <div>
            <ListHeaderLayout
                title={'Артикули'}
                createLink={'/articles/create/edit'}
                onQueryChange={handleQueryChange}
            />
            <ArticleTableFilters
                onFilterChange={handleFilterChange}
                showContent={() => showTable()}
            />
            <ArticlesTable  showTable={() => showTable()} articles={articles}/>
            <PaginationComponent
                pagination={pagination}
                onPageChange={handlePageChange}
            />

        </div>
    );
};

export default ArticleListPage;
