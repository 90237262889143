import { Card, Table} from "react-bootstrap";
import {formatDate} from "../../../../utils/dateFunctions.js";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {CardImage, CartCheckFill, EyeFill, PencilSquare} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

const CarsTable = ({
    cars,
    showTable
                   }) => {

    return (
        <Card>
            {showTable ? (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>№</th>
                        <th>Клиент</th>
                        <th>Рег. номер</th>
                        <th>Марка/Модел</th>
                        <th>ВИН</th>
                        <th>Първа регистратия</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    {cars.map((car) => (
                        <tr key={car.id}>
                            <td>{car.id}</td>
                            <td><Link to={`/clients/${car.client.id}`} target={'_blank'}>{car.client.name}</Link></td>
                            <td><Link to={`/cars/${car.id}`} >{car.registrationNumber}</Link></td>
                            <td>{car.carModel.modelManufacturerName}</td>
                            <td>{car.vinNumber}</td>
                            <td>{formatDate(car.firstRegisteredAt)}</td>
                            <td>
                                <TableLinkIconButton to={`/cars/${car.id}`} title={'Преглед'}><EyeFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/cars/${car.id}/edit`} title={'Редактирай'}><PencilSquare /></TableLinkIconButton>
                                <TableLinkIconButton to={`/cars/${car.id}/orders`} title={'Поръчки'}><CartCheckFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/cars/${car.id}/files`} title={'Файлове'}><CardImage /></TableLinkIconButton>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </Table>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default CarsTable;