import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useState, useEffect} from "react";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import {fetchClientCars, resetStateData as carListResetStateData} from "../../../../slices/carListSlice.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import {fetchClients, resetStateData as clientListResetStateData} from "../../../../slices/clientListSlice.js";
import FormInput from "../../../../components/Elements/FormInput.js";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import DateInput from "../../../../components/Elements/DateInput.js";
import {Button} from "react-bootstrap";
import AddClientModal from "./AddClientModal.js";
import {useDispatch, useSelector} from "react-redux";
import AddCarModal from "./AddCarModal.js";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";
import {addOrderComment, deleteOrderComment as deleteOrderCommentAction} from "../../../../slices/orderSlice.js";
import OrderCommentModal from "./OrderCommentModal.js";
import {v4 as uuidv4} from "uuid";
import {fetchInsurers} from "../../../../slices/insurertListSlice.js";
import FormRadio from "../../../../components/Elements/FormRadio.js";

const OrderForm = (
    {
        order,
        errors,
        orderStatuses,
        paymentTypes,
        onAttributeChange,
        partners
    }) => {
    const dispatch = useDispatch();
    const {data: cars, loading: carsLoading} = useSelector((state) => state.carList);
    const [showNewClientModal, setShowNewClientModal] = useState(false);
    const [showNewCarModal, setShowNewCarModal] = useState(false);
    const [showOrderCommentModal, setShowOrderCommentModal] = useState(false);
    const [orderCommentModalData, setOrderCommentModalData] = useState({});

    const closeNewClientModal = () => {
        setShowNewClientModal(false)
    }

    useEffect(() => {
        dispatch(carListResetStateData());
    }, []);

    useEffect(() => {
        if (cars.length === 1) {
            onAttributeChange('car', cars[0])
        }
    }, [cars]);

    const handleClientUpdate = (value) => {
        onAttributeChange('client', value);
        onAttributeChange('invoiceClient', value);
        onAttributeChange('car', null);
        dispatch(fetchClientCars({id: value.id}));
    }

    const handleCarUpdate = (value) => {
        onAttributeChange('car', value)
    }

    const closeAddCarModal = () => {
        setShowNewCarModal(false)
    }

    const handleOrderStatusChange = (name, item) => {
        if (item.settings?.needsComment) {
            setOrderCommentModalData({
                title: 'Сигурен ли си, че искаш да смениш на статус ' + item.name + '?',
                content: '',
                action: 'order_status_change',
                helpText: 'Ако не добавиш коментар промяната няма да бъде отразена',
                onSubmit: (comment) => {
                    onAttributeChange(name, item);
                    dispatch(addOrderComment({
                        id: uuidv4(),
                        action: 'order_status_change',
                        comment: comment,
                        actionDetail: item.name,
                    }))
                    setShowOrderCommentModal(false);
                }
            })
            setShowOrderCommentModal(true);
        } else {
            onAttributeChange(name, item);
        }
    }

    const deleteOrderComment = (comment) => {
        dispatch(deleteOrderCommentAction(comment));
    }

    const editOrderComment = (existingComment) => {
        setOrderCommentModalData({
            title: 'Промени коментар',
            content: existingComment.comment,
            action: existingComment.action,
            onSubmit: (comment) => {
                dispatch(addOrderComment({
                    id: uuidv4(),
                    action: 'order_status_change',
                    comment: comment,
                    actionDetail: existingComment.actionDetail
                }))
                setShowOrderCommentModal(false);
            }
        })
        setShowOrderCommentModal(true);
    }

    const handleIsPrivateChange = (name, value) => {
        if (value) {
            onAttributeChange('insurer', null);
            onAttributeChange('insurerClaimId', '');
        }
        onAttributeChange(name, value);
    }

    const closeOrderCommentModal = () => {
        setShowOrderCommentModal(false);
    }

    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col sm={4}>
                        <div className={'d-inline-block p-2'} style={{border: errors?.client ? '2px solid red' : 'none'}}>
                        <span>Клиент <br/>{order.client ? (order.client.name) : (
                            <span className={'text-danger text-decoration-underline fw-bold'}>! няма !</span>)}</span>
                        </div>
                        <Button className={'d-inline-block float-end'}
                                onClick={() => setShowNewClientModal(true)}>
                            {order.client ? 'Промени' : 'Добави'}
                        </Button>
                    </Col>
                    <Col sm={4}>
                        <SearchAutoCompleteComponent
                            onItemChange={onAttributeChange}
                            item={order.invoiceClient}
                            name={'invoiceClient'}
                            label={'Клиент фактура'}
                            fetchItems={fetchClients}
                            reducer={'clientList'}
                            errors={errors}
                            itemLabel={'searchLabel'}
                            inline={true}
                            resetStateData={clientListResetStateData}
                        />
                    </Col>
                    <Col md={4}>
                        <FormRadio label={'Частен ремонт'} errors={errors} onRadioChange={handleIsPrivateChange} name={'isPrivate'}
                                   options={[{value: true, label: 'Да'}, {value: false, label: 'Не'}]}
                                   selectedValue={order.isPrivate !== null ? order.isPrivate : ''} inline={true}
                                   />

                        <SearchAutoCompleteComponent
                                onItemChange={onAttributeChange}
                                item={order.insurer}
                                name={'insurer'}
                                label={'Засраховател'}
                                fetchItems={fetchInsurers}
                                reducer={'insurerList'}
                                errors={errors}
                                inline={true}
                                resetStateData={clientListResetStateData}
                                disabled={order.isPrivate !== false}
                            />
                        <FormInput name={'insurerClaimId'} label={'Номер на щета'} errors={errors}
                                   onChange={onAttributeChange}
                                   value={order.insurerClaimId}
                                   disabled={order.isPrivate !== false}
                        />
                    </Col>
                </Row>
                <Row className={'mt-2'}>
                    <Col sm={4}>
                        <div className={'d-inline-block p-2'}
                             style={{border: errors?.car ? '2px solid red' : 'none'}}>

                        <span>Автомобил <br/> {order.car?.id ? (order.car.searchLabel) : (
                            <span className={'text-danger text-decoration-underline fw-bold'}>! няма !</span>)}</span>
                        </div>
                            <Button className={'d-inline-block float-end'} onClick={() => setShowNewCarModal(true)}
                                    disabled={!order.client || carsLoading}>{order.car ? 'Промени' : 'Добави'}</Button>
                    </Col>
                    <Col sm={4}>
                        <FormNumericInput name={'mileage'} label={'Пробег'} errors={errors} onChange={onAttributeChange}
                                          value={order.mileage}
                        />
                    </Col>
                </Row>
                <Row>

                    <Col sm={4}>
                        <SelectInput
                            label={'Изпълнител'}
                            value={order.partner ? order.partner.id : ''}
                            name={'partner'}
                            errors={errors}
                            onChange={onAttributeChange}
                            selectOptions={partners}
                        />
                    </Col>
                    <Col sm={4}>
                        <SelectInput
                            label={'Статус'}
                            value={order.orderStatus ? order.orderStatus.id : ''}
                            name={'orderStatus'}
                            errors={errors}
                            onChange={handleOrderStatusChange}
                            selectOptions={
                                order.id === null
                                    ? orderStatuses.filter(status => status.label !== "cancelled")
                                    : orderStatuses
                            }
                        />
                        {order.newOrderComments.map((comment, index) =>
                            comment.action === 'order_status_change' ?
                                <div className={'mt-1 mb-2'} key={index}>
                                    <div><i>{comment.comment}</i></div>
                                    <Button className={'small-button me-1'}
                                            onClick={() => editOrderComment(comment)}>
                                        Редактирай коментар
                                    </Button>
                                    {!order.orderStatus?.settings.needsComment && (
                                        <Button className={'small-button btn-danger'}
                                                onClick={() => deleteOrderComment(comment)}>Изтрий коментар</Button>
                                    )}
                                </div> :
                                null
                        )}
                    </Col>
                    <Col sm={4}>
                        <SelectInput
                            label={'Начин плащане'}
                            value={order.paymentType ? order.paymentType.id : ''}
                            name={'paymentType'}
                            errors={errors}
                            onChange={onAttributeChange}
                            selectOptions={paymentTypes}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <DateInput label={'Дата приемане'} dateValue={order.startDate} name={'startDate'}
                                   onDateValueChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={4}>
                        <DateInput label={'Дата издаване'} dateValue={order.endDate} name={'endDate'}
                                   onDateValueChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={4}>
                        <DateInput label={'Дата фактура'} dateValue={order.invoiceDate} name={'invoiceDate'}
                                   onDateValueChange={onAttributeChange} errors={errors}/>
                        <Row>
                            <Col md={4}>
                                <FormInput errors={errors} onChange={onAttributeChange} name={'invoiceNumber'}
                                           value={order.invoiceNumber}
                                           label={'Номер фактура'}
                                />
                            </Col>
                            <Col md={8}>
                                <FormCheckbox name={'noInvoiceNumber'} label={'Без номер фактура'}
                                              onCheckboxChange={onAttributeChange} isChecked={order.noInvoiceNumber}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col sm={8}>
                        <FormInput errors={errors} onChange={onAttributeChange} name={'additionalInformation'}
                                   value={order.additionalInformation}
                                   label={'Бележка'} as={'textarea'}
                        />
                    </Col>
                </Row>
                <AddClientModal show={showNewClientModal}
                                closeModal={closeNewClientModal}
                                orderClient={order.client}
                                onNewClientUpdate={handleClientUpdate}
                />
                <AddCarModal show={showNewCarModal}
                             closeModal={closeAddCarModal}
                             orderCar={order.car}
                             onCarUpdate={handleCarUpdate}
                             clientCars={cars}
                             client={order.client}
                             clientCarsLoading={carsLoading}
                />
                {showOrderCommentModal && (
                    <OrderCommentModal
                        show={showOrderCommentModal}
                        onClose={closeOrderCommentModal}
                        data={orderCommentModalData}
                    />
                )}
            </Form.Group>

        </Form>
    )
}

export default OrderForm;