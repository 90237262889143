export const formatDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }
    return null;
};

export const dateToYmdFormat = (date) => {
    return date.toISOString().split("T")[0];
}

export const formatDateToBeautifulString = (dateString) => {
    const date = new Date(dateString);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('bg-BG', options).format(date);
}

export const formatDateTimeToBeautifulString = (dateString) => {
    const date = new Date(dateString);

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };

    return new Intl.DateTimeFormat('bg-BG', options).format(date);
}